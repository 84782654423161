<template>
  <div class="pp-container">
    <h1 class="title">Disclaimer</h1>
    <a class="wpembed-inline" href="https://app.websitepolicies.com/policies/view/5wl231t8">Disclaimer</a>
  </div>
</template>

<script>
export default {
  name: "DisclaimerView"
}
</script>

<style>
@media (max-width: 200px) {
  div.pp-container {
    margin: auto;
    width: 100%;
  }
}

@media (max-width: 600px) {
  div.pp-container {
    margin: auto;
    width: 90%;
  }
}

@media (max-width: 800px) {
  div.pp-container {
    margin: auto;
    width: 80%;
  }
}

@media (min-width: 800px) {
  div.pp-container {
    margin: auto;
    width: 60%;
  }
}

div.pp-container h1.title {
  color: #000;
  font-weight: bold;
}
div.pp-container h1, h2, h3, h4, h5, h6 {
  color: #23C9CC;
}

div.pp-container a {
  color: #012679;
}

div.pp-container div.wpembed-container {
  color: rgb(110, 125, 129);
}
</style>
