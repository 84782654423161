import { render, staticRenderFns } from "./TermsView.vue?vue&type=template&id=3a041b05&"
import script from "./TermsView.vue?vue&type=script&lang=js&"
export * from "./TermsView.vue?vue&type=script&lang=js&"
import style0 from "./TermsView.vue?vue&type=style&index=0&id=3a041b05&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports