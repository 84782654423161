<template>
  <Body></Body>
</template>

<script>
import Body from '@/components/Body'

export default {
  name: 'HomeView',

  components: {
    Body,
  }
}
</script>
