<template>
  <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-681959c sec-two elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="681959c" data-element_type="section">
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9b3e068 elementor-invisible" data-id="9b3e068" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-00824c1 elementor-widget elementor-widget-image" data-id="00824c1" data-element_type="widget" data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img decoding="async" width="1230" height="1125" src="/images/2022-12-Landing-Page-1.png" class="attachment-full size-full wp-image-234" alt="" loading="lazy" srcset="/images/2022-12-Landing-Page-1.png 1230w, /images/2022-12-Landing-Page-1-300x274.png 300w, /images/2022-12-Landing-Page-1-1024x937.png 1024w, /images/2022-12-Landing-Page-1-768x702.png 768w" sizes="(max-width: 1230px) 100vw, 1230px"/>
            </div>
          </div>
        </div>
      </div>

      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4a6631e" data-id="4a6631e" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-87056df elementor-widget elementor-widget-heading" data-id="87056df" data-element_type="widget" data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h2 class="elementor-heading-title elementor-size-default">Take control of<br>
                <span>your  health!</span>
              </h2>
            </div>
          </div>

          <div class="elementor-element elementor-element-815b26f elementor-widget elementor-widget-text-editor" data-id="815b26f" data-element_type="widget" data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p>
                Don't let missed doses or forgetfulness impact your health. Download the app today and take control of your medication regimen.
              </p>
            </div>
          </div>

          <div class="elementor-element elementor-element-8e9ffb9 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="8e9ffb9" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <link rel="stylesheet" href="/css/elementor-css-custom-widget-icon-box.min.css">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="far fa-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Profile Management
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="elementor-element elementor-element-185e752 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="185e752" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="far fa-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Medications Control
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="elementor-element elementor-element-c612d0e elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c612d0e" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="far fa-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Doses History
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="elementor-element elementor-element-27fdf44 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="27fdf44" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="far fa-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Doctor appointments history
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ControlSection'
}
</script>
