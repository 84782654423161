import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import SupportView from '@/views/SupportView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import TermsView from '@/views/TermsView.vue'
import DisclaimerView from '@/views/DisclaimerView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/support',
    name: 'support',
    component: SupportView
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicyView
  },
  {
    path: '/terms-conditions',
    name: 'termsConditions',
    component: TermsView
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: DisclaimerView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
