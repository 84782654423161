<template>
  <div id="app">
    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-dark-grayscale"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0 0.49803921568627"/><fefuncg type="table" tablevalues="0 0.49803921568627"/><fefuncb type="table" tablevalues="0 0.49803921568627"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-grayscale"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0 1"/><fefuncg type="table" tablevalues="0 1"/><fefuncb type="table" tablevalues="0 1"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-purple-yellow"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0.54901960784314 0.98823529411765"/><fefuncg type="table" tablevalues="0 1"/><fefuncb type="table" tablevalues="0.71764705882353 0.25490196078431"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-blue-red"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0 1"/><fefuncg type="table" tablevalues="0 0.27843137254902"/><fefuncb type="table" tablevalues="0.5921568627451 0.27843137254902"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-midnight"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0 0"/><fefuncg type="table" tablevalues="0 0.64705882352941"/><fefuncb type="table" tablevalues="0 1"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-magenta-yellow"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0.78039215686275 1"/><fefuncg type="table" tablevalues="0 0.94901960784314"/><fefuncb type="table" tablevalues="0.35294117647059 0.47058823529412"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-purple-green"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0.65098039215686 0.40392156862745"/><fefuncg type="table" tablevalues="0 1"/><fefuncb type="table" tablevalues="0.44705882352941 0.4"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 0 0" width="0" height="0" focusable="false" role="none" style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
      <defs>
        <filter id="wp-duotone-blue-orange"><fecolormatrix color-interpolation-filters="sRGB" type="matrix" values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "/><fecomponenttransfer color-interpolation-filters="sRGB"><fefuncr type="table" tablevalues="0.098039215686275 1"/><fefuncg type="table" tablevalues="0 0.66274509803922"/><fefuncb type="table" tablevalues="0.84705882352941 0.41960784313725"/><fefunca type="table" tablevalues="1 1"/></fecomponenttransfer><fecomposite in2="SourceGraphic" operator="in"/></filter>
      </defs>
    </svg>

    <a class="skip-link screen-reader-text" href="#content">Skip to content</a>

    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>
