<template>
  <div data-elementor-type="wp-page" data-elementor-id="8" class="elementor elementor-8">
    <TitleSection></TitleSection>

    <ControlSection></ControlSection>

    <MedicationsSection></MedicationsSection>

    <StepsSection></StepsSection>

    <!-- <VideoSection></VideoSection> -->

    <ScreenshotsSection></ScreenshotsSection>
  </div>
</template>

<script>
import TitleSection from '@/components/TitleSection'
import ControlSection from '@/components/ControlSection'
import MedicationsSection from '@/components/MedicationsSection'
import StepsSection from '@/components/StepsSection'
// import VideoSection from '@/components/VideoSection'
import ScreenshotsSection from '@/components/ScreenshotsSection'

export default {
  name: 'BodyComp',

  components: {
    TitleSection,
    ControlSection,
    MedicationsSection,
    StepsSection,
    // VideoSection,
    ScreenshotsSection,
  }
}
</script>
