<template>
  <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-f4a9920 sec-one elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f4a9920" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ddc0f89" data-id="ddc0f89" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-61b377f elementor-widget elementor-widget-heading" data-id="61b377f" data-element_type="widget" data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <!-- <style> -->
              <!-- /*! elementor - v3.10.1 - 17-01-2023 */ -->
              <!-- .elementor-heading-title { -->
              <!--   padding: 0; -->
              <!--   margin: 0; -->
              <!--   line-height: 1 -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-heading .elementor-heading-title[class*=elementor-size-] > a { -->
              <!--   color: inherit; -->
              <!--   font-size: inherit; -->
              <!--   line-height: inherit -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-heading .elementor-heading-title.elementor-size-small { -->
              <!--   font-size: 15px -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-heading .elementor-heading-title.elementor-size-medium { -->
              <!--   font-size: 19px -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-heading .elementor-heading-title.elementor-size-large { -->
              <!--   font-size: 29px -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-heading .elementor-heading-title.elementor-size-xl { -->
              <!--   font-size: 39px -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-heading .elementor-heading-title.elementor-size-xxl { -->
              <!--   font-size: 59px -->
              <!-- } -->
              <!-- </style> -->
              <h1 class="elementor-heading-title elementor-size-default">Managing your pills made easy with
                <span>Pellet!</span>
              </h1>
            </div>
          </div>

          <div class="elementor-element elementor-element-3eeb7d6 elementor-widget elementor-widget-text-editor" data-id="3eeb7d6" data-element_type="widget" data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <!-- <style> -->
              <!-- /*! elementor - v3.10.1 - 17-01-2023 */ -->
              <!-- .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap { -->
              <!--   background-color: #818a91; -->
              <!--   color: #fff -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap { -->
              <!--   color: #818a91; -->
              <!--   border: 3px solid; -->
              <!--   background-color: transparent -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap { -->
              <!--   margin-top: 8px -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter { -->
              <!--   width: 1em; -->
              <!--   height: 1em -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-text-editor .elementor-drop-cap { -->
              <!--   float: left; -->
              <!--   text-align: center; -->
              <!--   line-height: 1; -->
              <!--   font-size: 50px -->
              <!-- } -->
              <!---->
              <!-- .elementor-widget-text-editor .elementor-drop-cap-letter { -->
              <!--   display: inline-block -->
              <!-- } -->
              <!-- </style> -->
              <p>Do you struggle with remembering to take your medication? Our app is here to help.</p>
            </div>
          </div>

          <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-7924ad0 header-btns elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7924ad0" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <!-- <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1ff370f" data-id="1ff370f" data-element_type="column"> -->
              <!--   <div class="elementor-widget-wrap elementor-element-populated"> -->
              <!--     <div class="elementor-element elementor-element-2bb19a2 elementor-widget elementor-widget-image" data-id="2bb19a2" data-element_type="widget" data-widget_type="image.default"> -->
              <!--       <div class="elementor-widget-container"> -->
              <!--         <a href="#"> -->
              <!--           <img decoding="async" width="229" height="69" src="/images/2022-12-gpp.png" class="attachment-large size-large wp-image-184" alt="" loading="lazy"/> -->
              <!--         </a> -->
              <!--       </div> -->
              <!--     </div> -->
              <!--   </div> -->
              <!-- </div> -->
              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-70d792f" data-id="70d792f" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-0331d9c elementor-widget elementor-widget-image" data-id="0331d9c" data-element_type="widget" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <a href="#">
                        <img decoding="async" width="203" height="67" src="/images/2022-12-Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917-1.png" class="attachment-large size-large wp-image-22" alt="" loading="lazy"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8b82c19" data-id="8b82c19" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-191dfa1 elementor-invisible elementor-widget elementor-widget-image" data-id="191dfa1" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img decoding="async" width="1201" height="1051" src="/images/2022-12-bg-1.png" class="attachment-full size-full wp-image-243" alt="" loading="lazy" srcset="/images/2022-12-bg-1.png 1201w, /images/2022-12-bg-1-300x263.png 300w, /images/2022-12-bg-1-1024x896.png 1024w, /images/2022-12-bg-1-768x672.png 768w" sizes="(max-width: 1201px) 100vw, 1201px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleSectionComp'
}
</script>
