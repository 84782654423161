<template>
  <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-964300d sec-four elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="964300d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-da06422 hiw-col" data-id="da06422" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-498f1b3 elementor-widget elementor-widget-heading" data-id="498f1b3" data-element_type="widget" data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h2 class="elementor-heading-title elementor-size-default">How it Works -
                <span>3 Easy Steps</span>
              </h2>
            </div>
          </div>

          <div class="elementor-element elementor-element-efa8833 elementor-widget elementor-widget-text-editor" data-id="efa8833" data-element_type="widget" data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <!-- <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed -->
              <!--   <br/>ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.</p> -->
            </div>
          </div>

          <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-15a6182 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="15a6182" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dfe40a1" data-id="dfe40a1" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-af207a5 elementor-widget elementor-widget-heading" data-id="af207a5" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Register and create profile</h2>
                    </div>
                  </div>

                  <div class="elementor-element elementor-element-917d0e2 elementor-widget elementor-widget-text-editor" data-id="917d0e2" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Create an account and setup your first profile.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9801b4b" data-id="9801b4b" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-1ffe748 elementor-invisible elementor-widget elementor-widget-image" data-id="1ffe748" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <img decoding="async" width="536" height="441" src="/images/2022-12-Frame-27.png" class="attachment-large size-large wp-image-56" alt="" loading="lazy" srcset="/images/2022-12-Frame-27.png 536w, /images/2022-12-Frame-27-300x247.png 300w" sizes="(max-width: 536px) 100vw, 536px"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-af72f53 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="af72f53" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-94eacf4" data-id="94eacf4" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-9c5a61f elementor-invisible elementor-widget elementor-widget-image" data-id="9c5a61f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <img decoding="async" width="525" height="417" src="/images/2022-12-Frame-28.png" class="attachment-large size-large wp-image-57" alt="" loading="lazy" srcset="/images/2022-12-Frame-28.png 525w, /images/2022-12-Frame-28-300x238.png 300w" sizes="(max-width: 525px) 100vw, 525px"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0edb2b5" data-id="0edb2b5" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-ceae2de elementor-widget elementor-widget-heading" data-id="ceae2de" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Add medication and setup reminders</h2>
                    </div>
                  </div>

                  <div class="elementor-element elementor-element-6da0a46 elementor-widget elementor-widget-text-editor" data-id="6da0a46" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>
                        Add your first medication and set the frequency of how often you need to take it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-2dc16e3 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2dc16e3" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-09a6282" data-id="09a6282" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6c6b909 elementor-widget elementor-widget-heading" data-id="6c6b909" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Get Notified for your next dose!</h2>
                    </div>
                  </div>

                  <div class="elementor-element elementor-element-2a9f8e9 elementor-widget elementor-widget-text-editor" data-id="2a9f8e9" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Get notifications of your daily doses on it's time.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e1fe934" data-id="e1fe934" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-affdd6c elementor-invisible elementor-widget elementor-widget-image" data-id="affdd6c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <img decoding="async" width="431" height="421" src="/images/2022-12-Frame-29.png" class="attachment-large size-large wp-image-58" alt="" loading="lazy" srcset="/images/2022-12-Frame-29.png 431w, /images/2022-12-Frame-29-300x293.png 300w" sizes="(max-width: 431px) 100vw, 431px"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="elementor-element elementor-element-46823bf timeline-img elementor-hidden-mobile elementor-widget elementor-widget-image" data-id="46823bf" data-element_type="widget" data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img decoding="async" width="120" height="1097" src="/images/2022-12-Group-13.png" class="attachment-full size-full wp-image-55" alt="" loading="lazy" srcset="/images/2022-12-Group-13.png 120w, /images/2022-12-Group-13-112x1024.png 112w" sizes="(max-width: 120px) 100vw, 120px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StepsSection'
}
</script>
