<template>
  <div data-elementor-type="footer" data-elementor-id="130" class="elementor elementor-130 elementor-location-footer">
    <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-5c0e0f7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5c0e0f7" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-83f529f" data-id="83f529f" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-8934b90 elementor-widget elementor-widget-image" data-id="8934b90" data-element_type="widget" data-widget_type="image.default">
              <div class="elementor-widget-container">
                <a href="/">
                  <img width="244" height="44" src="/images/2022-12-Logo-2.png" class="attachment-large size-large wp-image-133" alt="" loading="lazy"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-7449ec5" data-id="7449ec5" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-25bd190 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="25bd190" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-efe55e4" data-id="efe55e4" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-91b26e9 elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu" data-id="91b26e9" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;i class=\&quot;fas fa-caret-down\&quot;&gt;&lt;\/i&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                      <div class="elementor-widget-container">
                        <nav migration_allowed="1" migrated="0" class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                          <ul id="menu-1-91b26e9" class="elementor-nav-menu sm-vertical">
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-120">
                              <a href="/terms-conditions" class="elementor-item elementor-item-anchor">Terms & Conditions</a>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-121">
                              <a href="/privacy-policy" class="elementor-item elementor-item-anchor">Privacy Policy</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-493db56" data-id="493db56" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-28e498a elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu" data-id="28e498a" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;i class=\&quot;fas fa-caret-down\&quot;&gt;&lt;\/i&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                      <div class="elementor-widget-container">
                        <nav migration_allowed="1" migrated="0" class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                          <ul id="menu-1-28e498a" class="elementor-nav-menu sm-vertical">
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-122">
                              <a href="/disclaimer" class="elementor-item elementor-item-anchor">Disclaimer</a>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-123">
                              <a href="/support" class="elementor-item elementor-item-anchor">Support / Help</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-dfe6e60" data-id="dfe6e60" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-c1eff0b elementor-widget elementor-widget-heading" data-id="c1eff0b" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">
                  Download the App Now!
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-97640e2" data-id="97640e2" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-412b705 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="412b705" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e9280c2" data-id="e9280c2" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-1911d61 elementor-widget elementor-widget-image" data-id="1911d61" data-element_type="widget" data-widget_type="image.default">
                      <div class="elementor-widget-container">
                        <a href="#">
                          <img width="259" height="93" src="/images/2022-12-as.png" class="attachment-large size-large wp-image-134" alt="" loading="lazy"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-df4d77a" data-id="df4d77a" data-element_type="column"> -->
                <!--   <div class="elementor-widget-wrap elementor-element-populated"> -->
                <!--     <div class="elementor-element elementor-element-5ac084f elementor-widget elementor-widget-image" data-id="5ac084f" data-element_type="widget" data-widget_type="image.default"> -->
                <!--       <div class="elementor-widget-container"> -->
                <!--         <a href="#"> -->
                <!--           <img width="261" height="93" src="/images/2022-12-gps.png" class="attachment-large size-large wp-image-135" alt="" loading="lazy"/> -->
                <!--         </a> -->
                <!--       </div> -->
                <!--     </div> -->
                <!--   </div> -->
                <!-- </div> -->
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FooterComp'
}
</script>
