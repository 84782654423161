<template>
  <div data-elementor-type="wp-page" data-elementor-id="8" class="elementor elementor-8">
    <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-f4a9920 sec-one elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f4a9920" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ddc0f89" data-id="ddc0f89" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-61b377f elementor-widget elementor-widget-heading" data-id="61b377f" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h1 class="elementor-heading-title elementor-size-default">Need
                  <span>Help!</span>
                </h1>
              </div>
            </div>

            <div class="elementor-element elementor-element-3eeb7d6 elementor-widget elementor-widget-text-editor" data-id="3eeb7d6" data-element_type="widget" data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <p>
                  Or just send us a message to say 
                  <b> Hello! </b>
                </p>
              </div>
            </div>

            <section class="section">
              <div class="container">
                <div class="input-group">
                  <input class="input" id="name" type="text" placeholder="Name" required autocomplete="off" v-model="name">
                </div>

                <div class="input-group">
                  <input class="input" id="email" type="email" placeholder="Email" value="" required autocomplete="off" v-model="email">
                </div>

                <div class="input-group">
                  <input class="input" id="subject" type="text" placeholder="Subject" value="" required autocomplete="off" v-model="subject">
                </div>

                <div class="input-group">
                  <textarea class="textarea" id="message" placeholder="Message" rows="5" required v-model="message"></textarea>
                </div>

                <button class="button submit-btn" @click="postSupport">Submit</button>
              </div>
            </section>

          </div>
        </div>

        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8b82c19" data-id="8b82c19" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-191dfa1 elementor-invisible elementor-widget elementor-widget-image" data-id="191dfa1" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
              <div class="elementor-widget-container">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SupportView',

  data () {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  },

  methods: {
    postSupport () {
      var data = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      };
      let hasError = false;

      Object.keys(data).forEach(function (key) {
        if (data[key] === "" || data[key] === null || data[key] === undefined) {
          hasError = true
        }
      })
      if (hasError) { alert("Please fill the all the fields!"); return }

      fetch("https://groceries-api.masys.mx/supports", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }).then(function (res) {
          this.name = ""
          this.email = ""
          this.subject = ""
          this.message = ""

          console.log("Request complete! response:", res);
          alert("Thanks for contacting us, we will come back to you soon as fast as we can!");
        });
    }
  }
}
</script>

<style type="text/css" media="all" scoped>
.submit-btn {
  color: #fff;
  background-color: #23C9CC;
  border-color: #23C9CC;
  border-radius: 0.5rem;
  font-weight: bold;
}

.section {
  width: 100%;
}

.section .container {
  width: 100%;
  min-height: 500px;
}

.input-group {
  display: inline-flex;
  background-color: #ffffff;
  align-items: center;
  border-radius: 0.5rem;
  overflow: hidden;
  font-size: 2rem;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin-bottom: 15px;
}
.input-group input {
  border: 0;
  padding: 1rem;
}
.input-group input:focus {
  outline: 0;
}
.input-group textarea {
  border: 0;
  padding: 1rem;
}
</style>
