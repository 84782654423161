<template>
  <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-bf859df elementor-section-full_width slider-sec elementor-section-height-default elementor-section-height-default" data-id="bf859df" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eb8aa6c" data-id="eb8aa6c" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-16f9f93 sec-five elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="16f9f93" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-859b96d" data-id="859b96d" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-996ac26 elementor-widget elementor-widget-heading" data-id="996ac26" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">
                        Screenshots
                      </h2>
                    </div>
                  </div>

                  <div class="elementor-element elementor-element-2f6ade0 elementor-widget elementor-widget-text-editor" data-id="2f6ade0" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Don't wait, download <span style="color: #23C9CC;font-weight: bold;">Pellet</span> now and enjoy the peace of mind that comes with never missing a dose again.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-8b625cc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8b625cc" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-4706958 elementor-invisible" data-id="4706958" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-9598430 elementor-widget elementor-widget-image" data-id="9598430" data-element_type="widget" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <img decoding="async" width="702" height="1052" src="/images/2022-12-3.png" class="attachment-full size-full wp-image-374" alt="" loading="lazy" srcset="/images/2022-12-3.png 702w, /images/2022-12-3-200x300.png 200w, /images/2022-12-3-683x1024.png 683w" sizes="(max-width: 702px) 100vw, 702px"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-447e216 elementor-invisible" data-id="447e216" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-5eb4b11 elementor-widget elementor-widget-image" data-id="5eb4b11" data-element_type="widget" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <img decoding="async" width="702" height="1052" src="/images/2022-12-2.png" class="attachment-full size-full wp-image-373" alt="" loading="lazy" srcset="/images/2022-12-2.png 702w, /images/2022-12-2-200x300.png 200w, /images/2022-12-2-683x1024.png 683w" sizes="(max-width: 702px) 100vw, 702px"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-000efd3 elementor-invisible" data-id="000efd3" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6556eda elementor-widget elementor-widget-image" data-id="6556eda" data-element_type="widget" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <img decoding="async" width="702" height="1052" src="/images/2022-12-1.png" class="attachment-full size-full wp-image-372" alt="" loading="lazy" srcset="/images/2022-12-1.png 702w, /images/2022-12-1-200x300.png 200w, /images/2022-12-1-683x1024.png 683w" sizes="(max-width: 702px) 100vw, 702px"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ScreenshotsSection'
}
</script>
