<template>
  <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-bff2191 sec-three elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bff2191" data-element_type="section">
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a13eaea" data-id="a13eaea" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-ff3df2d elementor-widget elementor-widget-heading" data-id="ff3df2d" data-element_type="widget" data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h2 class="elementor-heading-title elementor-size-default">
                Some awesome<br>
                  words
                <span>about the app!</span>
              </h2>
            </div>
          </div>
          <div class="elementor-element elementor-element-c351d33 elementor-widget elementor-widget-text-editor" data-id="c351d33" data-element_type="widget" data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p>
                Our pill reminder app is a lifesaver for anyone who struggles with remembering to take their medication. It is user-friendly, easy to navigate and provides peace of mind. With its powerful reminder system, you'll never miss a dose again and can trust that you're staying on top of your medication regimen.
              </p>
            </div>
          </div>

          <div class="elementor-element elementor-element-e613bdb elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="e613bdb" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="fas fa-check-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Carefully Designed
                    </span>
                  </h3>
                  <p class="elementor-icon-box-description">
                    Our pill reminder app is carefully designed with the user in mind. We understand that managing medication can be complex and overwhelming, so we created an app that is easy to use and intuitive. The user interface is simple and straightforward, making it easy to add medications, set reminders, and track your progress.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="elementor-element elementor-element-30ea975 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="30ea975" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="fas fa-check-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Seamless Sync
                    </span>
                  </h3>
                  <p class="elementor-icon-box-description">
                    Our app also features seamless sync across devices, which means you can access your medication schedule and reminders from any device, whether it be your phone or tablet. This feature is particularly useful for those who need to take medication at different times throughout the day and may not always have their primary device with them.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="elementor-element elementor-element-7cd2f2f elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="7cd2f2f" data-element_type="widget" data-widget_type="icon-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-icon-box-wrapper">
                <div class="elementor-icon-box-icon">
                  <span class="elementor-icon elementor-animation-">
                    <i aria-hidden="true" class="fas fa-check-circle"></i>
                  </span>
                </div>
                <div class="elementor-icon-box-content">
                  <h3 class="elementor-icon-box-title">
                    <span>
                      Multiple Porifles
                    </span>
                  </h3>
                  <p class="elementor-icon-box-description">
                    Additionally, the app allows you to manage medications of your family memebers and add Caregivers to share and help you manage their medications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c2f94c4 elementor-invisible" data-id="c2f94c4" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-373ab49 elementor-widget elementor-widget-image" data-id="373ab49" data-element_type="widget" data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img decoding="async" width="771" height="923" src="/images/2022-12-Group-18.png" class="attachment-full size-full wp-image-45" alt="" loading="lazy" srcset="/images/2022-12-Group-18.png 771w, /images/2022-12-Group-18-251x300.png 251w, /images/2022-12-Group-18-768x919.png 768w" sizes="(max-width: 771px) 100vw, 771px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MedicationsSection'
}
</script>
