<template>
  <div data-elementor-type="header" data-elementor-id="124" class="elementor elementor-124 elementor-location-header">
    <section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-b391346 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b391346" data-element_type="section">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-e6bedeb" data-id="e6bedeb" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-bc465a5 elementor-widget elementor-widget-image" data-id="bc465a5" data-element_type="widget" data-widget_type="image.default">
              <div class="elementor-widget-container">
                <!-- <style> -->
                <!-- /*! elementor - v3.10.1 - 17-01-2023 */ -->
                <!-- .elementor-widget-image { -->
                <!--   text-align: center -->
                <!-- } -->
                <!---->
                <!-- .elementor-widget-image a { -->
                <!--   display: inline-block -->
                <!-- } -->
                <!---->
                <!-- .elementor-widget-image a img[src$=".svg"] { -->
                <!--   width: 48px -->
                <!-- } -->
                <!---->
                <!-- .elementor-widget-image img { -->
                <!--   vertical-align: middle; -->
                <!--   display: inline-block -->
                <!-- } -->
                <!-- </style> -->
                <a href="/">
                  <img width="244" height="44" src="/images/2022-12-logo.png" class="attachment-full size-full wp-image-126" alt="" loading="lazy"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HeaderComp'
}
</script>
